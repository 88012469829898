
import React from 'react'
import { Link } from 'react-router-dom';
import "../Header/header.css"

function Header() {

    const estilo={background:"darkcyan",fontWeight:"450", color:"#ffffff",fontSize:"13pt",fontFamily:"-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif",borderBottom: "2px solid yellow"};
    const logoF={color:"#ffffff",fontWeight:"350",fontSize:"20pt",fontFamily:"-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif"};
    const logoT={marginBottom:"5px"};

    
    return (
    <nav className="navegar navbar fixed-top navbar-expand-lg" aria-label="Eleventh navbar example" style={estilo}>
    <div className="container">
      <Link to={"#"} className="navbar-brand"style={logoF}>
         FAIRFLEX <img src="Images/favicon.png" alt=""height='40'style={logoT}/>
      </Link>
      <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarsExample09" aria-controls="navbarsExample09" aria-expanded="false" aria-label="Toggle navigation">
        <span className="navbar-toggler-icon"></span>
      </button>

      <div className="collapse navbar-collapse" id="navbarsExample09" >
        <ul className="navbar-nav  ms-auto mb-2 mb-lg-0">
          <li className="nav-item dropdown"  >
            <a className="nav-link dropdown-toggle"href="#banner"role="button" data-bs-toggle="dropdown" aria-expanded="false">Serviços</a >
            <ul className="dropdown-menu lista">
            <li><a className="dropdown-item" href="#servicos">Flex Escolar</a></li>
            <li><a className="dropdown-item disabled" aria-disabled="true" href="#">Agência Imobilíaria</a></li>
            <li><hr className="dropdown-divider"/></li>
            <li><a className="dropdown-item disabled" aria-disabled="true" href="#">Logística</a></li>
          </ul>
          </li>
          
          <li className="nav-item dropdown ">
              <a className="nav-link dropdown-toggle" href="#testemunho"role="button" data-bs-toggle="dropdown" aria-expanded="false">Clientes</a>
              <ul className="dropdown-menu lista">
                  <li><a className="dropdown-item" href="#testemunho">Sobre a FairFlex</a></li>
                  <li><hr className="dropdown-divider"/></li>
              </ul>
          </li>
            
          
            <li className="nav-item dropdown ">
                <a className="nav-link dropdown-toggle" href="#features"role="button" data-bs-toggle="dropdown" aria-expanded="false">Sobre Nós</a>
                <ul className="dropdown-menu lista">
                <li><a className="dropdown-item" href="#features">O que nos Diferencia</a></li>
                <li><a className="dropdown-item" href="#historia">Origem</a></li>
                <li><a className="dropdown-item" href="#historia">Missão</a></li>
                <li><a className="dropdown-item" href="#historia">Visão</a></li>
                <li><hr className="dropdown-divider"/></li>
                <li><a className="dropdown-item" href="#equipe">Nossa Equipe</a></li>

                </ul>
          
          </li>
          <li className="nav-item">
              <a className="nav-link" href="#footer">Contactos</a>
            </li>                
        </ul>           
      </div>
      <a href='/app' className=' btn btn-outline-light btn-lg '>Fazer Login</a>
    </div>
  </nav>
  );
        
        
}

export default Header

