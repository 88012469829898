import React from 'react'
import "../Testemunho/testemunho.css"

function Testemunho() {

  const fig={alignItem:"center",justifyItems:"center",justifyContent:"center",textAlign:"center"}
  return <section id='testemunho'>
        <div className='container '>

                <div className='titulo'>
                <h1>O que o cliente diz sobre a FairFlex</h1>
                <h4>Qualidade e Eficiência no fornecimento de serviços </h4>
                <p></p>
                <br></br>
                </div>

    <div className='row teste' >
            <div id="carouselExampleSlidesOnly" class="carousel slide " data-bs-ride="carousel" style={fig}>
              <div class="carousel-inner">
                    <div class="carousel-item active" >
                    <br></br><h3>Excelente serviços para o acompanhamento do dia a dia, sendo flexivel o suficiente para a maior parte dos usos. Seja para transportes dos meus filhos  ou  para viagens particulares, a FairFlex tem se revelado uma ferramenta muito ùtil e intuitiva.</h3>
                    <img src="./Images/favicon.png" alt="..." />
                    <em>Deny Mondlane - Maputo </em>
                    </div>

                    <div class="carousel-item">
                    <br></br><h3>Excelente serviços para o acompanhamento do dia a dia, sendo flexivel o suficiente para a maior parte dos usos. Seja para transportes dos meus filhos  ou  para viagens particulares, a FairFlex tem se revelado uma ferramenta muito ùtil e intuitiva.</h3>
                    <img src="./Images/favicon.png" alt="..."/>
                    <em>Deny Mondlane - Maputo </em>
                    </div>

                    <div class="carousel-item">
                    <br></br><h3>Excelente serviços para o acompanhamento do dia a dia, sendo flexivel o suficiente para a maior parte dos usos. Seja para transportes dos meus filhos  ou  para viagens particulares, a FairFlex tem se revelado uma ferramenta muito ùtil e intuitiva.</h3>
                    <img src="./Images/favicon.png" alt="..."/>
                    <em>Deny Mondlane - Maputo </em>
                    </div>
          
              </div>
                    <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleSlidesOnly" data-bs-slide="prev">
                    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                    <span class="visually-hidden">Previous</span>
                    </button>
                    <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleSlidesOnly" data-bs-slide="next">
                    <span class="carousel-control-next-icon" aria-hidden="true"></span>
                    <span class="visually-hidden">Next</span>
                    </button>
            </div>
            </div>
            <div className='row'>
              <p></p>
            </div>
    <p class="d-inline-flex gap-2">
  <a class="btn btn-success" data-bs-toggle="collapse" href="#collapseExample" role="button" aria-expanded="false" aria-controls="collapseExample">
    Dar Testemunho
  </a>
 </p>

<div class="collapse" id="collapseExample">
  <div class="">
  <form class="row  conteudoT">
        <div className='row'>
            <div class="col-md-4">
        <label for="exampleFormControlInput1" class="form-label">Endereço de email</label>
        <input type="email" class="form-control" id="exampleFormControlInput1" placeholder="name@example.com"/>
        </div>
        <div class="col-md-8">
        <label for="exampleFormControlTextarea1" class="form-label">Comentario</label>
        <textarea type="comment" class="form-control" id="exampleFormControlTextarea1" rows="3"placeholder="comentarios"></textarea>
        </div>
        </div>
        <div className='row'>
            <br></br>
        </div>
        <div className='row'>
  <div class="col-md-4">
    <label for="validationDefault04" class="form-label">Cidade</label>
    <select class="form-select" id="validationDefault04" required>
      <option selected disabled value="">Escolha...</option>
      <option>Maputo</option>
      <option>Marracuene</option>
      <option>Matola</option>
    </select>
  </div>
    <div class="col-md-4">
    <button class="btn btn-primary" type="submit">Enviar Comentario</button>
  </div>
  </div>
  
</form>

  </div>
</div>

</div>

      
    
 </section>
}

export default Testemunho
