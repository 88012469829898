import React from 'react'
import Header from './Componentes/Header/header'
import Banner from './Componentes/Banner/banner'
import Features from './Componentes/Features/features'
import Historia from './Componentes/Historia/historia'
import { AnimatePresence } from 'framer-motion'
import Equipe from './Componentes/Equipe/equipe'
import Footer from './Componentes/Footer/footer'
import Testemunho from './Componentes/Testemunho/testemunho'
import Servicos from './Componentes/Servicos/servicos'


function Site() {
  return (
    <div>
      <AnimatePresence mode="wait">
      <Header/>
      <Banner/>
      <Historia/>
      <Features/>
      <Equipe/>
      <Servicos/>
      <Testemunho/>
      
      <Footer/>
      </AnimatePresence>
    </div>
  )
}

export default Site
