import React from 'react'
import "../../Footer/footer.css"


export default function contacts() {
  const but={color:"green",fontSize:"13pt"}
    return (
        <div>

        <h5>Contactos</h5>   
        <div class="accordion accordion-flush contatos " id="accordionFlushExample">
  <div class="accordion-item contatos">
    <h2 class="accordion-header contatos">
      <button class="accordion-button collapsed contatos" type="button"style={but} data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
        Contacto comercial
      </button>
    </h2>
    <div id="flush-collapseOne" class="accordion-collapse collapse contatos" data-bs-parent="#accordionFlushExample">
      <div class="accordion-body contatos"> Sr(a) Túlia <code>(+258) 84 844 5754</code></div>
    </div>
  </div>
  <div class="accordion-item contatos">
    <h2 class="accordion-header contatos">
      <button class="accordion-button collapsed contatos" type="button"style={but} data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo">
      Contacto operacional
      </button>
    </h2>
    <div id="flush-collapseTwo" class="accordion-collapse collapse contatos"style={but} data-bs-parent="#accordionFlushExample">
      <div class="accordion-body contatos"> Sr. Elisio <code>(+258) 84 803 2645</code> </div>
    </div>
  </div>
  <div class="accordion-item contatos">
    <h2 class="accordion-header contatos">
      <button class="accordion-button collapsed contatos" type="button"style={but} data-bs-toggle="collapse" data-bs-target="#flush-collapseThree" aria-expanded="false" aria-controls="flush-collapseThree">
        Whatsapp
      </button>
    </h2>
    <div id="flush-collapseThree" class="accordion-collapse collapse contatos" data-bs-parent="#accordionFlushExample">
      <div class="accordion-body contatos"> Escritório <a href='https://whatsapp.com/' target='blank'> <code>(+258) 84 803 2645</code></a></div>
    </div>
  </div>
</div>
            <br></br>
            <h5>Endereço</h5>
            <h6>Bairro Laulane, Q. 04, parcela no 237 Rua da Beira no 38,
                 Distrito Municipal Kamavota, na cidade de Maputo</h6>
</div>
    )
}