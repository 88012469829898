import React from 'react'
import { FiCreditCard, FiMail, FiUser, FiUsers } from "react";


export const origem = "Fundada em 2024, a FairFlex Lda é uma empresa que, através das suas  especialidades ou subsidiárias, se dedica a gestão operacional de todo tipo de processos, desde transportes, compras, recursos humanos…Nosso objectivo é ajudar nossos clientes a ganhar mais tempo oferencendo serviços de gestão que garantem tranquilidade e segurança. A FairFlex procura diferenciar-se combinando princípios de transparência com uso das tecnologia de informação avançada na gestão operacional dos interesses dos seus clientes oferecendo deste modo serviços de qualidade e com maior flexibilidade na entrega";
export const visao = "Ser uma empresa de referência nacional e internacional na gestão de serviços operacionais, promovendo um clima de confiança, segurança e de eficácia dos serviços e produtos através da combinação entre gestão operacional, tecnologia e transparência";
export const missao = "Garantir a qualidade, transparência e flexibilidade na gestão dos nossos serviços para satisfazer as necessidades dos nossos clientes, criando políticas e programas que visam assegurar que nossos serviços e produtos cheguem com transparência, flexibilidade e ao melhor custo benefício";
export const valores = ["Transparência ", "Compromentimento", "Competencia", "Etica e deontologia profissional", "Comunicação", "Sigilo profissional"];


