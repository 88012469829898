import React from 'react'
import "../Footer/footer.css"
import Contacts from './components/contacts'
import RedesSociais from './components/redesSociais'

function Footer() {
  
return <section id='footer'>
    <div className='container-fluid'>

                <div className='row'>             
                <div className='rodape col-lg-4 feature-box'>
                                    <RedesSociais/>                               
                </div>
                <div className='rodape col-lg-4 feature-box'>
                                     <Contacts/>
                </div>

                <div className='rodape col-lg-4 feature-box '>
                <h5>FairFlex</h5>
                <div class=" list-group list-group-flush border border-0  ">
                    <a href="#servicos" class="li list-group-item list-group-item-action contatos" aria-current="true">
                      Flex Escolar
                    </a>
                    <a href="#testemunho" class=" li list-group-item list-group-item-action contatos">Clientes</a>
                    <a href="#historia" class="li list-group-item list-group-item-action contatos">Sobre Nós</a>
                    <a href="#equipe" class=" li list-group-item list-group-item-action contatos">Nossa Equipe</a>
                    <a href="#features" class="li list-group-item list-group-item-action contatos">Diferenciais</a>
                  </div>
                                     
                </div>
               
                </div>
                    <div className='row text-center'>
                      <div>
                        <hr width="auto"></hr>
                        <p>Desenvolvido por DBBS -2024</p>
                        </div>
                    </div>
         </div>
</section>

}


export default Footer
