import { BrowserRouter,Routes,Route } from "react-router-dom";
import Site from "./Site/site";

function App() {
  return <BrowserRouter>
  <Routes>
         <Route exact path='/' element={<Site/>} />
          {/* <Route exact path='/app' element={<Login/>} />
           <Route exact path='/app/novaconta' element={<NovaConta/>}/>
          <Route exact path='/app/resetsenha' element={<ResetSenha/>}/>
          <Route exact path='/app/home' element={<Home/>}/>
          <Route exact path='/app/novocliente'element={<NovoCliente/>}/>
          <Route exact path='/app/editarclientes/:id'element={<EditarClientes/>}/>*/}
  </Routes>
      </BrowserRouter>;
}

export default App;
