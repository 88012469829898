import React from 'react';
import "../Features/features.css"

function Features(){
    return <section id='features'>
        <div className='container'>

                   
                        <div className='titulo'>
                            <h1>Porquê a FairFlex</h1>
                            <h4>Nossos Diferenciais</h4>
                            <hr width="auto"></hr>
                        </div>
                    


                    <div class="row row-cols-1 row-cols-md-3 g-4">
  <div class="col">
    <div class="card h-100 feature-box ft">
      <i className="icon fa-solid fa-earth-americas fa-5x"></i>
      <div class="card-body">
      <h5>Melhor gestão de tempo</h5><p></p>
      <p>Nossa experiência em tecnologias de informação garantem uma gestão operacional de qualidade das suas preocupações</p>  </div>
      <div class="card-footer">
        <small class="text-body-secondary"></small>
      </div>
    </div>
  </div>
  <div class="col">
    <div class="card h-100 feature-box ft">
       <i className="icon fa-solid fa-heart fa-5x"></i>
      <div class="card-body">
      <h5>Cuidamos de tudo por si</h5><p></p>
      <p>Nós ajudamos a cuidar de tudo para ganhar mais tempo. Tudo, desde levar a sua criança para a escola, cuidar do seu expediente bem como fazer suas compras e entregas</p>
      </div>
      <div class="card-footer">
        <small class="text-body-secondary"></small>
      </div>
    </div>
  </div>
  <div class="col">
    <div class="card h-100 feature-box ft">
    <i className=" icon fa-solid fa-table-columns fa-5x"></i>
      <div class="card-body">
      <h5>Flexibilidade e segurança</h5><p></p>
      <p>Nossos processos de gestão baseiam-se em princípios de transparência e flexibilidade para oferecer aos nossos clientes tranquilidade e segurança</p></div>
      <div class="card-footer">
        <small class="text-body-secondary"></small>
      </div>
    </div>
  </div>
</div>
           
        </div>
    </section>
       
    

}

export default Features;