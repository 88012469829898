import React from 'react'
import "../Servicos/servicos.css"
import {motion,MotionConfig} from "framer-motion";


function Servicos() {

const rota={color:"rgb(241, 166, 26)", fontSize:"14pt", fontWeight:"550"}
const alt= {minHeight:"120px"}
const mar={width:"480px"}


    return <section id='servicos'>

    <div className='container'>

                <div className='titulo'>
                <h1>Flex Escolar</h1>
                <h4>Pontualidade  . Conforto  . Segurança</h4>
                <p></p>
                </div>

                <div class="card text-bg-light border-light">
  <img src="./Images/serv.jpg" class="card-img" alt="..."/>
  <div class="card-img-overlay">
  <div class="row">
              

            </div><br></br>

  <div class="row">
      <div class="col-4 ">
        <div class="list-group" id="list-tab" role="tablist">
        <MotionConfig transition={{ duration:0.3 }}>
          <motion.a class="list-group-item list-group-item-action active ju" id="list-home-list" data-bs-toggle="list" href="#list-home" role="tab" aria-controls="list-home"   
          whileHover={{ scale: 1.05}}
          whileTap={{ scale: 0.9,x:80}}>Serviços </motion.a>
          <motion.a class="list-group-item list-group-item-action ju " id="list-profile-list" data-bs-toggle="list" href="#list-profile" role="tab" aria-controls="list-profile"
          whileHover={{ scale: 1.05}}
          whileTap={{ scale: 0.9,x:80}}>Pacotes</motion.a>
          <motion.a class="list-group-item list-group-item-action ju" id="list-messages-list" data-bs-toggle="list" href="#list-messages" role="tab" aria-controls="list-messages"
          whileHover={{ scale: 1.05}}
          whileTap={{ scale:0.9,x:80}}>Rotas</motion.a>
          <motion.a class="list-group-item list-group-item-action ju" id="list-settings-list" data-bs-toggle="list" href="#list-settings" role="tab" aria-controls="list-settings"
          whileHover={{ scale: 1.05}}
          whileTap={{ scale: 0.9,x:80}}>Ofertas</motion.a>
          </MotionConfig>
        </div>
      </div>

      <div class="col-8">
        <div class="tab-content" id="nav-tabContent">
          <div class="tab-pane fade show active" id="list-home" role="tabpanel" aria-labelledby="list-home-list">
              <p class="d-inline-flex gap-1 serv">
                <motion.a class="btn btn-primary" data-bs-toggle="collapse" href="#multiCollapseExample0" role="button" aria-expanded="false" aria-controls="multiCollapseExample0"
                whileHover={{ scale: 1.3 }}
                whileTap={{ scale: 1.0}}
                >Flex Escolar</motion.a>
              </p>
      <div class="row">
          <div class="col">
            <div class="collapse multi-collapse" id="multiCollapseExample0">
              <div class="card card-body ju">
                  <p> Serviços de transporte escolar privado oferecido em dois pacotes: <h5>Standard e Premium.</h5></p>
              </div>
            </div>
          </div>
      </div>
  </div>

      <div class="tab-pane fade" id="list-profile" role="tabpanel" aria-labelledby="list-profile-list">
      <MotionConfig transition={{ duration:0.2 }}>
            <p class="d-inline-flex gap-4">
              <motion.a class="btn btn-primary" data-bs-toggle="collapse" href="#multiCollapseExample1" role="button" aria-expanded="false" aria-controls="multiCollapseExample1"
              whileHover={{ scale: 1.1 }}
              whileTap={{ scale: 1.0,rotate:180}}>Standard</motion.a>
              <motion.button class="btn btn-primary" type="button" data-bs-toggle="collapse" data-bs-target="#multiCollapseExample2" aria-expanded="false" aria-controls="multiCollapseExample2"
              whileHover={{ scale: 1.1 }}
              whileTap={{ scale: 1.0, rotate:180}}>Premium</motion.button>
            </p>
      </MotionConfig>
<div class="row">
  <div class="col">
    <div class="collapse multi-collapse" id="multiCollapseExample1">
      <div class="card card-body ju">
      Transporte escolar privado partilhado usando carros pequenos de 5 á 7 lugares.
      </div>
    </div>
  </div>
  <div class="col">
    <div class="collapse multi-collapse" id="multiCollapseExample2">
      <div class="card card-body ju">
      Transporte escolar exclusivo usando carros pequenos de 5 lugares.
      </div>
    </div>
  </div>
</div></div>
      <div class="tab-pane fade" id="list-messages" role="tabpanel" aria-labelledby="list-messages-list"><p>
      
  <motion.button class="btn btn-primary" type="button" data-bs-toggle="collapse" data-bs-target="#collapseWidthExample" aria-expanded="false" aria-controls="collapseWidthExample"
  whileHover={{ scale: 1.1,background:"green" }}
  whileTap={{ scale: 1.0,x:30}}>
    
    
    Rotas Disponivies
  </motion.button>
 
</p>
<div style={alt}>
  <div class="collapse collapse-horizontal" id="collapseWidthExample">
    <div class="card card-body ju"style={mar}>
    <p>•	Fomento - Cidade da Matola - Fomento</p>
    <p>•	Kongolote - Cidade de Maputo - Kongolote</p>
    <p>•	Guava Mateque - CMC - Cidade Maputo - Guava Mateque</p>
    <p>•	Cumbeza - Albasine - Cidade de Maputo - Cumbeza</p>
    <p>•	Boquiso - Intaka (Vila) - Cidade de Maputo - Boquisso</p>
    <p style={rota}>•	Estamos abertos a criar novas rotas</p>
    </div>
  </div>
</div></div>
      <div class="tab-pane fade" id="list-settings" role="tabpanel" aria-labelledby="list-settings-list">Atendemos às cidades de Maputo e Matola com rotas específicas e flexíveis, carros rastreados e de acordo com a necessidade do cliente.</div>
    </div>
  </div>
</div>
       </div>
    
  </div>
</div>
    </section>
}

export default Servicos
