import {motion} from "framer-motion";

export default function CarouselItem({ origem, imagem, active,duration}) {
   
   
    return (
        
        <div class={`carousel-item ${active}`} data-bs-interval={duration}>
            <div class="card text-bg-light border-light carta ">
                <img src={imagem} class="card-img" alt="..." />
                <motion.div class="card-img-overlay conteudo justify-content"
                    transition={{ duration:3}}
                    exit={{ width: 100 }}
                    whileHover={{}}
                      whileInView={{
                        x: [900,0]
                           /*  y:[-100,0]*/
                      }}
                    >
                 
                  {origem}
                 
                </motion.div>
            </div>
        </div>
    )
}
